// src/components/Section.js
import React from 'react';
import './Section.css';

const Section = ({ title, body }) => {
  return (
    <section className="section">
      <h2>{title}</h2>
      <p dangerouslySetInnerHTML={{ __html: body }}></p>
    </section>
  );
};

export default Section;