// src/components/Footer.js
import React from 'react';
import './Footer.css';
import GithubIcon from '../svgs/github.svg';
import EmailIcon from '../svgs/envelope-solid.svg';
import LinkedInIcon from '../svgs/linkedin.svg';

const Footer = () => {
  return (
    <footer className="footer">
      <p>© 2024 Alexander Sangurima. All rights reserved.</p>
      <div className="footer-links">
        <a href="https://github.com/alehxs" target="_blank" rel="noopener noreferrer">
          <img src={GithubIcon} alt="GitHub" />
        </a>
        <a href="mailto:asangurima@pm.me">
          <img src={EmailIcon} alt="Email" />
        </a>
        <a href="https://linkedin.com/in/asangurima" target="_blank" rel="noopener noreferrer">
          <img src={LinkedInIcon} alt="LinkedIn" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;